import React, { useState } from "react";
import loadable from "@loadable/component";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import { ModuleWrapper } from "../moduleWrapper";

const VideoComponent = loadable(() => import("react-player"));

const VideoBlock = ({ config, video, layout = "center", autoPlay }) => {
  const [isActive, setActive] = useState(false);

  return (
    <ModuleWrapper {...config} className="grid grid-cols-14">
      <div
        className={cx({
          "col-start-1 col-end-15": layout === "full",
          "col-start-2 col-end-14": layout === "center",
          "col-start-2 col-end-14 md:col-end-8": layout === "drop-left",
          "col-start-2 col-end-14 md:col-start-8": layout === "drop-right",
          "col-start-1 col-end-15 md:col-end-9": layout === "left",
          "col-start-1 col-end-15 md:col-start-8": layout === "right",
        })}
      >
        <div className="bg-black">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={`video-${isActive}`}
              className="aspect-w-16 aspect-h-9 w-full relative bg-black"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <VideoComponent
                playing
                url={video}
                muted={!isActive}
                loop={!isActive}
                controls={isActive}
                width="100%"
                height="auto"
                onEnded={() => setActive(false)}
                playsinline={!isActive}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default VideoBlock;
